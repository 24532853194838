.grid {
  display: grid;
  gap: var(--padding-content);

  h1 {
    margin-left: 5px;
  }

  h1 .number {
    font-size: 1.5rem;
    vertical-align: middle;
  }

  .item {
    padding: var(--padding-content);
    background: var(--color-background);
    border-radius: var(--border-radius);
    border: var(--border-solid);
  }

  .link {
    cursor: pointer;

    &:hover {
      color: var(--color-link-hover);
    }
  }

  .item.link {
    display: block;
  }
}

.grid { grid-template-columns: 1fr; }
.grid-1 { grid-template-columns: 1fr; }
.grid-2 { grid-template-columns: 1fr; }
.grid-3 { grid-template-columns: 1fr; }
.grid-4 { grid-template-columns: 1fr; }
.colspan-2 { grid-column: span 1; }

@media screen and (min-width: 720px) {
  .grid-4 { grid-template-columns: repeat(2, 1fr); }
  .grid-4 .colspan-2 { grid-column: span 2; }
}

@media screen and (min-width: 992px) {
  .grid { grid-template-columns: repeat(2, 1fr); }
  .grid-1 { grid-template-columns: 1fr; }
  .grid-2 { grid-template-columns: repeat(2, 1fr); }
  .grid-3 { grid-template-columns: repeat(2, 1fr); }
  .grid-4 { grid-template-columns: repeat(3, 1fr); }
  .grid-2 .colspan-2 { grid-column: span 2; }
  .grid-3 .colspan-2 { grid-column: span 2; }
}

@media screen and (min-width: 1400px) {
  .grid { grid-template-columns: repeat(3, 1fr); }
  .grid-1 { grid-template-columns: 1fr; }
  .grid-2 { grid-template-columns: repeat(2, 1fr); }
  .grid-3 { grid-template-columns: repeat(3, 1fr); }
  .grid-4 { grid-template-columns: repeat(4, 1fr); }
}
