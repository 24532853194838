.flex {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: var(--padding-content);
}

.flex-item {
  position: relative;
  background: var(--color-background);
  padding: var(--padding-content);
  border-radius: var(--border-radius);
  border: var(--border-solid);
}
