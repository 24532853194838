body > .map { display: none !important; }

.map {
  position: relative;
  height: calc(100vh - 64px);
  max-height: 800px;
  width: 100%;
}

.map-controls {
  position: absolute;
  z-index: 9999;
  display: flex;
  flex-direction: row;
  gap: var(--padding-content-half);

  button {
    position: relative;
    padding: var(--padding-content);
    color: var(--color-map-tooltip);
    background:  var(--color-map-tooltip-background);
    border-radius: var(--border-radius);
    width: 32px;
    height: 32px;

    &.map-control-off {
      mat-icon {
        opacity: 0.2;
      }
    }
  }

  mat-icon {
    position: absolute;
    bottom: 3px;
    left: 3px;
  }
}

.map-controls-tl {
  top: var(--padding-content);
  left: var(--padding-content);
}

.map-controls-bl {
  bottom: var(--padding-content);
  left: var(--padding-content);
}

.map-label-realm {
  position: absolute;
  top: 0 !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  white-space: nowrap;
  font-size: 14px;
  text-shadow: #000 1px 1px 0;
}

.map-label-realm-above {
  top: -18px !important;
}

.map-label-coord {
  position: absolute;
  top: -10px !important;
  left: 0 !important;
  width: 0 !important;
  height: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  white-space: nowrap;
  font-size: 10px;
  text-shadow: #000 1px 1px 0;
}

.leaflet-container {
  border-radius: var(--border-radius);
  background: #0F1520;
  //background-image: url(/assets/game/map_bg.webp);
  //background-repeat: repeat;
  outline: 0;
}

.leaflet-popup-content {
  margin: 20px 6px 6px 6px !important;
}

.leaflet-popup-close-button {
  color: var(--color-map-tooltip) !important;
}

.leaflet-popup-content-wrapper {
  color: var(--color-map-tooltip) !important;
  background-color: var(--color-map-tooltip-background) !important;
  border-radius: var(--border-radius) !important;
  border: 1px solid var(--color-map-tooltip-border) !important;
}

.leaflet-popup-tip {
  background-color: var(--color-map-tooltip-border) !important;
}

.leaflet-control-zoom-in, .leaflet-control-zoom-out {
  color: var(--color-map-tooltip) !important;
  background-color: var(--color-map-tooltip-background) !important;
  border-radius: var(--border-radius) !important;
  border: 1px solid var(--color-map-tooltip-border) !important;
  &.leaflet-disabled {
    color: var(--color-map-tooltip-disabled) !important;
  }
}

.leaflet-control-zoom-in {
  border-radius: var(--border-radius) var(--border-radius) 0 0 !important;
}

.leaflet-control-zoom-out {
  border-radius: 0 0 var(--border-radius) var(--border-radius) !important;
  border-top-width: 0 !important;
}

.s-leaflet-flex {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.s-leaflet-grid {
  display: grid;
  gap: 6px;
  grid-template-columns: repeat(2, 1fr);
  *:nth-child(n + 3) {
    grid-column: span 2;
  }
}

.s-leaflet-tooltip {
  .container {
    display: inline-block;
    border: none !important;
  }
  a {
    color: var(--color-map-tooltip) !important;
    text-decoration: none;
  }
  iframe {
    vertical-align: top;
    border-radius: var(--border-radius) !important;
  }
}

.s-leaflet-hasicon {
  position: relative;
  min-width: 32px;
  .s-leaflet-maticon {
    position: absolute;
    left: 4px;
  }
}

.s-leaflet-check-label {
  margin-left: 22px;
}

.s-leaflet-maticon {
  font-family: 'Material Symbols Outlined';
  font-variation-settings: 'FILL' 1;
  font-size: 22px;
  line-height: 17px;
}

/* Responsive */

.s-leaflet-vid { max-width: 100%; }
.s-leaflet-item .s-leaflet-maticon-desktop { display: none; }

@media screen and (max-width: 719px) {
  .s-leaflet-grid .menu-label { padding-left: unset !important; }
  .s-leaflet-vid { max-height: 135px; }
}

@media screen and (min-width: 720px) {
  .s-leaflet-item .s-leaflet-maticon-desktop { display: block; }
  .s-leaflet-vid { max-height: 270px; }
}
