.background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  height: 100lvh;
  z-index: -999999;
  background: var(--background);
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;
}

html {
  background: #000;
}

.background::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  height: 100lvh;
  backdrop-filter: var(--background-filter);
}

body {
  background-color: unset;
  // background: var(--color-background);
  color: var(--color);
  font-family: 'Roboto', sans-serif;

  position: relative;
  width: 100%;
  padding: 0;

  &.menu-shown:not(.menu-folded) {
    padding-left: var(--menu-width);
  }
}

.mono {
  font-family: 'Roboto Mono', monospace;
}

a, .link {
  color: var(--color);
  // text-decoration: none;

  &:hover {
    color: var(--color-link-hover);
  }
}

p {
  margin-bottom: var(--padding-content);
}

.text-link {
  color: var(--color-link);
  text-decoration: underline;
  cursor: pointer;
  &:visited { color: var(--color-link-visited); }
  &:hover, &:active, &:focus { color: var(--color-link-hover); }
}

.icon {
  width: 24px;
  height: 24px;
}

// a with link
.link {
  text-decoration: underline;
  cursor: pointer;
}

.action {
  cursor: pointer;
  &:hover {
    color: var(--color-link-hover);
  }
}

button, .button {
  color: var(--color);
  padding: var(--padding-input);
  background: var(--color-button-background);
  border: 1px solid var(--color-input-border);
  border-radius: var(--border-radius);
  display: inline-block;

  &:hover {
    background: var(--color-button-hover-background);
  }

  &:disabled {
    background: var(--color-button-hover-background);
  }
}

.button-action {
  position: relative;
  border-radius: 50%;
  width: 64px;
  height: 64px;

  mat-icon {
    position: absolute;
    width: 32px;
    height: 32px;
    font-size: 32px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.danger {
  color: var(--color-danger);
  background: var(--color-danger-background);
  border-color: var(--color-danger-accent);
}

.button-danger {
  color: var(--color-danger);
  background: var(--color-danger-background);
  border-color: var(--color-danger-accent);

  &:hover {
    background: var(--color-danger-accent);
  }
}

.button-group {
  display: inline-block;

  button, .button {
    border: 1px solid var(--color-input-border);
    border-radius: 0;

    &:first-child {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }

    &:last-child {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }

    &:not(:last-child) {
      border-right: none;
    }
  }
}

input, select, textarea {
  display: block;
  width: 100%;
  color: var(--color);
  padding: var(--padding-input);
  border-radius: var(--border-radius);
  background: var(--color-background);
  border: 1px solid var(--color-input-border);

  &:focus {
    outline: none;
    border-color: var(--color-input-border);
  }
}

input[type="checkbox"] {
  width: auto;
  display: inline-block;
}

input[type="date"] {
  color-scheme: dark;
}

input[type="date"].input-date-click {
  position: relative;

  &::-webkit-calendar-picker-indicator {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    padding: 0;
    color: transparent;
    background: transparent;
  }
}

.input-no-arrows {
  -moz-appearance: textfield;
  appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.input-hidden {
  width: 0; height: 0;
  padding: 0; margin: 0;
  border: 0;
}

.input-search {
  position: relative;

  input {
    padding-left: 36px;
  }

  mat-icon {
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    opacity: 1;
    transition: opacity var(--animation-short);
  }
}

select {
  vertical-align: top;
  height: 38px; // Match input height
}

.border {
  border: 1px solid var(--color-border);
}

.active {
  color: var(--color-active);
}

.expired {
  color: var(--color-expired);
  font-style: italic;
}

.sky-flex {
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-end;
  gap: var(--padding-content);
}

// Scrollbar
body { scrollbar-width: thin; }
::-webkit-scrollbar {
  --size: 12px;
  width: var(--size);
  height: var(--size);
}

::-webkit-scrollbar-track {
  background: var(--color-scroll-track);
  border: 1px solid var(--color-scroll-track-border);
  &:horizontal {
    border-radius: 50px;
  }
}
::-webkit-scrollbar-thumb {
  background: var(--color-scroll-thumb);
  border-radius: 50px;
  border: 1px solid var(--color-scroll-thumb-border);
}
::-webkit-scrollbar-corner {
  background: var(--color-scroll--humb);
}

// Bootstrap
.tooltip-inner {
  max-width: 100vw;
}

// Global classes
.c-main { color: var(--color) !important; }
.c-accent { color: var(--color-accent) !important; }
.c-full { color: var(--color-new) !important; }
.c-completed { color: var(--color-new) !important; }
.c-partial { color: var(--color-old) !important; }
.c-wb { color: var(--color-wing-buff) !important; }
.c-check { color: var(--color-unlock-check) !important; }
.c-warn-accent { color: var(--color-warning-accent) !important; }
.c-old { color: var(--color-old) !important; }
.c-new { color: var(--color-new) !important; }
.p-rel { position: relative !important; }
.p-abs { position: absolute !important; }
.t-left { text-align: left !important; }
.t-center { text-align: center !important; }
.t-right { text-align: right !important; }
.ws-nw { white-space: nowrap !important; }
.ws-pw { white-space: pre-wrap !important; }
.ws-pl { white-space: pre-line !important; }
.d-inline-block { display: inline-block !important; }
.fw-bold { font-weight: bold !important; }
.v-top { vertical-align: top !important; }
.v-middle { vertical-align: middle !important; }
.us-none { user-select: none !important; }

.mt { margin-top: var(--padding-content) !important; }
.mr { margin-right: var(--padding-content) !important; }
.mb { margin-bottom: var(--padding-content) !important; }
.ml { margin-left: var(--padding-content) !important; }
.pad-0 { padding: 0 !important; }
.pad { padding: var(--padding-content) !important; }
.pt { padding-top: var(--padding-content) !important; }
.pr { padding-right: var(--padding-content) !important; }
.pb { padding-bottom: var(--padding-content) !important; }
.pl { padding-left: var(--padding-content) !important; }
.margin { margin: var(--padding-content) !important; }
.mt-half { margin-top: var(--padding-content-half) !important; }
.mr-half { margin-right: var(--padding-content-half) !important; }
.mb-half { margin-bottom: var(--padding-content-half) !important; }
.ml-half { margin-left: var(--padding-content-half) !important; }
.mt-auto { margin-top: auto !important; }
.mr-auto { margin-right: auto !important; }
.mb-auto { margin-bottom: auto !important; }
.ml-auto { margin-left: auto !important; }
.pad-half { padding: var(--padding-content-half) !important; }
.pt-half { padding-top: var(--padding-content-half) !important; }
.pr-half { padding-right: var(--padding-content-half) !important; }
.pb-half { padding-bottom: var(--padding-content-half) !important; }
.pl-half { padding-left: var(--padding-content-half) !important; }
.gap-half { gap: var(--padding-content-half) !important; }
.gap-0 { gap: 0 !important; }
.w-100 { width: 100% !important; }
.h-100 { height: 100% !important; }
.o-0 { opacity: 0 !important; }
.o-1 { opacity: 1 !important; }
.td-u { text-decoration: underline !important; }

.float-left { float: left !important; }
.float-right { float: right !important; }

.sky-border { border: var(--border); }
.sky-border-solid { border: var(--border-solid); }

.br { border-radius: var(--border-radius) !important; }
.br-0 { border-radius: 0 !important}
.br-b-0 { border-bottom-left-radius: 0 !important; border-bottom-right-radius: 0 !important; }
.br-t-0 { border-top-left-radius: 0 !important; border-top-right-radius: 0 !important; }
.br-l-0 { border-top-left-radius: 0 !important; border-bottom-left-radius: 0 !important; }
.br-r-0 { border-top-right-radius: 0 !important; border-bottom-right-radius: 0 !important; }

.of-hidden { overflow: hidden !important; }

.flex-align-start { align-items: flex-start !important; }
.flex-align-center { align-items: center !important; }
.flex-align-end { align-items: flex-end !important; }
.flex-rows { flex-direction: row !important; }
.flex-cols { flex-direction: column !important; }
.flex-wrap { flex-wrap: wrap !important; }
.flex-nowrap { flex-wrap: nowrap !important; }
.flex-justify-center { justify-content: center !important; }

.dye-red { color: #93362f; }
.dye-purple { color: #af76ad; }
.dye-blue { color: #4f81b4; }
.dye-cyan { color: #73c1c1; }
.dye-green { color: #7f9558; }
.dye-yellow { color: #bc9d53; }
.dye-black {
  color: #232323;
  filter: drop-shadow(1px 0px 0px #666) drop-shadow(0px 1px 0px #666) drop-shadow(-1px 0px 0px #666) drop-shadow(0px -1px 0px #666)
}
.dye-white { color: #c1c1c1; }

// Global layout
@import "flex.less";
@import "card.less";
@import "grid.less";
@import "container.less";

p:last-child {
  margin-bottom: 0;
}

.unlock-check {
  position: absolute;
  right: 1px;
  bottom: 0;
  color: var(--color-unlock-check-ref);
  opacity: 0;
  transition: opacity var(--animation-short);
  pointer-events: none;
  text-shadow: #000 1px 1px 0;

  &.unlocked {
    opacity: 1;
    transition: opacity var(--animation-short);
  }

  &.self {
    color: var(--color-unlock-check);
  }
}

.point { cursor: pointer !important; }
.no-point { cursor: default !important; }

.wiki {
  position: relative;
  display: inline-block;
  width: 38px;
  height: 38px;
  border-radius: var(--border-radius);
  background-color: #5ECCF2;
  background-image: url(/assets/external/sky-wiki-favicon-full.png);
  background-size: 32px 32px;
  background-position: center;
  background-repeat: no-repeat;

  a {
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  mat-icon {
    position: absolute;
    top: 1px;
    right: 3px;
  }

  img {
    position: absolute;
    top: 3px;
    right: 4px;
  }
}

.menu-icon {
  position: absolute;
}

.menu-no-label {
  padding-left: 24px;
}

.menu-label {
  padding-left: 32px;
}

// Prevent SVG icon clipping.
mat-icon {
  font-variation-settings: 'FILL' 1;
  font-size: 24px;
  line-height: 24px;
}

mat-icon:not(.icon-middle) svg {
  vertical-align: unset;
}

mat-icon.currency { fill: var(--color-candle); }
.currency { color: var(--color-candle); fill: var(--color-candle); }
mat-icon.seasonal { fill: var(--color-seasonal); }
.seasonal { color: var(--color-seasonal); fill: var(--color-seasonal); }

.highlight {
  border: 1px solid var(--color-highlight) !important;
  border-radius: var(--border-radius) !important;
  box-shadow: var(--shadow) var(--color-highlight) !important;
}

.highlight-complete {
  border: 1px solid var(--color-progress-full) !important;
  border-radius: var(--border-radius) !important;
  box-shadow: var(--shadow) var(--color-progress-full) !important;
}

.highlight-attention {
  border: 1px solid var(--color-attention) !important;
  border-radius: var(--border-radius) !important;
  box-shadow: var(--shadow) var(--color-attention) !important;
}

.highlight-favourite {
  border: 1px solid var(--color-favourite) !important;
  border-radius: var(--border-radius) !important;
  box-shadow: var(--shadow) var(--color-favourite) !important;
}

.scroll-x {
  overflow-x: auto;
  width: min-content;
  min-width: 100%;
  max-width: calc(var(--menu-width) - 2 * var(--padding-content));
}

/* Animations */
@keyframes spin {
  from {transform: rotate(0deg);}
  to {transform: rotate(360deg);}
}

.spin {
  animation: spin 2s linear infinite;
}

.currency-gained {
  position: fixed;
  transform: translate(-50%, -50%);
  line-height: 24px;
  font-size: 24px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 1);
  pointer-events: none;
}

/* Intro.js */
.introjs-tooltip {
  color: #000;
  font-size: 16px;
}
.introjs-tooltiptext {
  padding: 10px;
}
.introjs-tooltip-header {
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
}

ngb-tooltip-window {
  z-index: 99999999 !important; // On top of Intro.js highlight.
}

/* Responsive */

.show-desktop { display: none; }
@media screen and (min-width: 720px) {
  .show-desktop { display: unset; }
  .show-mobile { display: none; }
}
