:root {
  --hue: 240;
  --saturation: 17%;
  --background: url(/assets/game/background/isle.webp);
}

// Variables
:root {
  --color: #fff;
  --color-accent: #999;
  --color-active: var(--color-new);
  --color-attention: #49ffea;
  --color-background: hsla(var(--hue), var(--saturation), 12%, 0.8);
  --color-background-solid: hsl(var(--hue), var(--saturation), 15%);
  --color-background-solid-light: hsl(var(--hue), var(--saturation), 25%);
  --color-border: hsla(var(--hue), var(--saturation), 8%, 0.5);
  --color-border-solid: hsl(var(--hue), var(--saturation), 15%);
  --color-border-highlight: hsl(var(--hue), var(--saturation), 35%);
  --color-button-background: hsla(var(--hue), var(--saturation), 12%, 0.8);
  --color-button-hover-background: hsla(var(--hue), var(--saturation), 25%, 0.8);
  --color-button-solid-background: hsla(var(--hue), var(--saturation), 12%, 1);
  --color-button-solid-hover-background: hsla(var(--hue), var(--saturation), 25%, 1);
  --color-card-background: hsla(var(--hue), var(--saturation), 15%, 0.35);
  --color-card-header-background: hsla(var(--hue), var(--saturation), 12%, 0.75);
  --color-card-solid-header-background: hsla(var(--hue), var(--saturation), 20%);
  --color-card-solid-background: hsl(var(--hue), var(--saturation), 15%);
  --color-cost-border: hsl(var(--hue), var(--saturation), 40%);
  --color-success: #fff;
  --color-success-accent: #3b9d3b;
  --color-success-background: #185a18;
  --color-success-card-background: #185a1880;
  --color-danger: #fff;
  --color-danger-accent: #9d3b3b;
  --color-danger-background: #5a1818;
  --color-danger-card-background: #5a181880;
  --color-warning-accent: #ff852b;
  --color-expired: #b7b7b7;
  --color-favourite: #ffcf00;
  --color-highlight: #d28efd;
  --color-image-inset: hsla(var(--hue), var(--saturation), 12%, 0.8);
  --color-input-border: var(--color-border-highlight);
  --color-item: #828282;
  --color-item-active: #c5b167;
  --color-link: hsl(var(--hue), 75%, 85%);
  --color-link-hover: hsl(var(--hue), 75%, 75%);
  --color-link-visited: hsl(280, 50%, 70%);
  --color-menu-background: hsla(var(--hue), var(--saturation), 15%, 0.6);
  --color-menu-border: hsl(var(--hue), var(--saturation), 80%);
  --color-menu-item-active-background: hsl(var(--hue), var(--saturation), 18%);
  --color-menu-item-background: transparent;
  --color-menu-unfold-background: hsl(var(--hue), var(--saturation), 25%);
  --color-menu-unfold-border: hsl(var(--hue), var(--saturation), 45%);
  --color-progress-background: #0e2b2c;
  --color-progress-fill: #256c6f;
  --color-progress-full: #007300;
  --color-scroll-thumb: hsl(var(--hue), var(--saturation), 15%);
  --color-scroll-thumb-border: hsl(var(--hue), var(--saturation), 30%);
  --color-scroll-track: hsl(var(--hue), var(--saturation), 25%);
  --color-scroll-track-border: hsl(var(--hue), var(--saturation), 45%);
  --color-table-header-background: hsla(var(--hue), var(--saturation), 3%, 0.75);
  --color-tree-background: hsla(var(--hue), var(--saturation), 15%, 0.5);
  --color-tree-footer-background: hsla(var(--hue), var(--saturation), 15%, 0.8);
  --color-tree-footer-border: hsl(var(--hue), var(--saturation), 40%);
  --color-tree-line: #fff;
  --color-tree-unlock-background: hsla(var(--hue), var(--saturation), 15%, 0.8);
  --color-unlock-check: var(--color-new);
  --color-unlock-check-background: #01340199;
  --color-unlock-check-ref: var(--color-old);

  --color-map-tooltip-background: hsl(var(--hue), var(--saturation), 20%);
  --color-map-tooltip: #fff;
  --color-map-tooltip-disabled: #666;
  --color-map-tooltip-border: hsl(var(--hue), var(--saturation), 50%);

  --color-candle: #ddd;
  --color-new: #32cd32;
  --color-old: #ffa93e;
  --color-wing-buff: #feff87;
  --color-seasonal: #fdab2b;

  --dye-none: #888;
  --dye-red: #93362f;
  --dye-purple: #af76ad;
  --dye-blue: #4f81b4;
  --dye-cyan: #73c1c1;
  --dye-green: #7f9558;
  --dye-yellow: #bc9d53;
  --dye-black: #232323;
  --dye-white: #c1c1c1;

  --background-filter: blur(0px) brightness(0.8);
  --backdrop-blur: blur(10px);

  --shadow: 0px 0px 5px 2px;
  --shadow-attention: 0px 0px 5px 2px var(--color-attention);
  --shadow-highlight: 0px 0px 5px 2px var(--color-highlight);
  --shadow-favourite: 0px 0px 5px 2px var(--color-favourite);
  --shadow-clock: drop-shadow(1px 1px 1px #000);

  --img-preview-height: 160px;

  // These values are also defined in some places such as item-icons.component.ts!
  --item-size: 64px;
  --item-size-large: 96px;
  --item-size-medium: 48px;
  --item-size-small: 32px;
  --item-size-mini: 24px;

  --icon-size-cost: 18px;

  --animation-short: 150ms;
  --animation-medium: 300ms;

  --menu-width: 44px;

  --border: 1px solid var(--color-border);
  --border-solid: 1px solid var(--color-border-solid);
  --border-solid-highlight: 1px solid var(--color-border-highlight);
  --border-radius: 8px;

  --padding-content: 10px;
  --padding-content-half: 5px;
  --padding-input: 6px 8px;
}

// #region Responsive
@media screen and (min-width: 992px) {
  :root {
    --menu-width: 200px;
  }
}
// #endregion

// #region Themes

:root[data-theme="isle"] {
  --hue: 240;
  --saturation: 17%;
  --background: url(/assets/game/background/isle.webp);
}

:root[data-theme=""], // Default theme
:root[data-theme="cozy"] {
  --hue: 140;
  --saturation: 20%;
  --background: url(/assets/game/realms/Aviary.webp);
}

:root[data-theme="peaks"] {
  --hue: 330;
  --saturation: 17%;
  --color-highlight: #f55dd4;
  --background: url(/assets/game/background/peaks.webp);
}

:root[data-theme="reef"] {
  --hue: 230;
  --saturation: 17%;
  --background: url(/assets/game/background/reef.webp);
}

:root[data-theme="cold"] {
  --hue: 190;
  --saturation: 0%;
  --background: url(/assets/game/background/village.webp);
}

:root[data-theme="sandy"] {
  --hue: 25;
  --saturation: 40%;
  --background: url(/assets/game/background/oasis.webp);
}

:root[data-theme="dark"] {
  --hue: 240;
  --saturation: 0%;
  --background: url(/assets/game/background/void_jelly.webp);
}

:root[data-theme="love"] {
  --hue: 205;
  --saturation: 70%;
  --background: url(/assets/game/background/love2024.webp);
}

:root[data-theme="moomin"] {
  --hue: 205;
  --saturation: 10%;
  --color-highlight: hsl(205, 30%, 50%);
  --background: url(/assets/game/background/moomin.webp);
}

:root[data-theme="wonderland"] {
  --hue: 0;
  --saturation: 30%;
  --background: url(/assets/game/background/feast2024.webp);
}

// #endregion
